body{
  height: 100vh;
  width: 100%;
}

header.body-wrapper{ 
  position: fixed !important;
  top: 0;
  background-color: #4384AB;
}

.body-wrapper {
  max-width: 1200px;
  margin: auto;
  margin-top: 64px;
  padding: 20px;
}

/*
  Main Page Styles
*/
.mainContainer{
  background-image: url("../assets/tyler-callahan-662157-unsplash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: none;
  display: flex;
}
.headlineOverlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: none;
}
.headlineContainer{
  text-align: center;
  height: 50vh;
  width: 75vw;
  margin: auto;
  overflow: none;
  display: flex;
}
.headline{
  color: white;
}
.getStarted{
  color: white !important;
}

/*
  Header styles
*/

.header{
  background-color: #2196f3;
  height: auto;
  width: auto;
}
.button:hover{
  color: white !important;
}

.headerLayout{
  float: right;
  margin-top: 2px;
}
.headerField{
  height: 50%;
}
.headerTitle{
  font-size: 25px;
  color: white;
}
.headerTitle:hover{
  color: white;
}
.search{
  background-color: white;
  border-radius: 10px;
  width: 100%;
}
.search:first-child{
  margin-left: 10px;
}
.searchContainer{
  width: 400px;
}
.logo{
  height: 40px;
}
/* Sign In*/
.signInContainer{
  background-color: #F1F1F1;
  height: 100vh;
}
.signInCard{
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  width: 500px;
}

.signInSpacing{
  margin-top: 30px;
  display: flex;
  clear: both;
  align-items: center;
  justify-content: center;

}

.signInSpacing p{
  margin: 0;

}

.signInLinks{
  width: 100%;
}
/* Sign Up*/
.signUpContainer{
  background-color: #F1F1F1;
  height: 100vh;
}
.signUpCard{
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  width: 500px;
}

.signUpSpacing{
  margin-top: 30px;
}

.signInField{
  border-radius: 25;
  background-color: white;
}

.forgotPw{
  color: white;
}
a{
  color: inherit;
  text-decoration: none;
}
a:hover{
  color: #2196f3;
}

input:-webkit-autofill {
-webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}

/* Profile */
.profile{
  min-height: 590px;
}
.profileMain{
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
}
.profileInfo{
  min-height: 550px;
}
.profileContent{
  min-height: 550px;
  overflow-y: auto;
}
.profilePicture{
  width: 100%;
}
.profileField{
  margin: 20 20 20 20;
}
.editProfileButton{
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.closeEdit{
  float: right;
}
.editButton{
  float: right;
}
.editSection{
  clear: both;
}
.dialog{
  width: 600px;
}

.wrapper{
  height: 100%;
}
li{
  list-style: none;
}
