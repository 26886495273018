body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  height: 100vh;
  width: 100%;
}

header.body-wrapper{ 
  position: fixed !important;
  top: 0;
  background-color: #4384AB;
}

.body-wrapper {
  max-width: 1200px;
  margin: auto;
  margin-top: 64px;
  padding: 20px;
}

/*
  Main Page Styles
*/
.mainContainer{
  background-image: url(/static/media/tyler-callahan-662157-unsplash.11b04bcb.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: none;
  display: -webkit-flex;
  display: flex;
}
.headlineOverlay{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: none;
}
.headlineContainer{
  text-align: center;
  height: 50vh;
  width: 75vw;
  margin: auto;
  overflow: none;
  display: -webkit-flex;
  display: flex;
}
.headline{
  color: white;
}
.getStarted{
  color: white !important;
}

/*
  Header styles
*/

.header{
  background-color: #2196f3;
  height: auto;
  width: auto;
}
.button:hover{
  color: white !important;
}

.headerLayout{
  float: right;
  margin-top: 2px;
}
.headerField{
  height: 50%;
}
.headerTitle{
  font-size: 25px;
  color: white;
}
.headerTitle:hover{
  color: white;
}
.search{
  background-color: white;
  border-radius: 10px;
  width: 100%;
}
.search:first-child{
  margin-left: 10px;
}
.searchContainer{
  width: 400px;
}
.logo{
  height: 40px;
}
/* Sign In*/
.signInContainer{
  background-color: #F1F1F1;
  height: 100vh;
}
.signInCard{
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  width: 500px;
}

.signInSpacing{
  margin-top: 30px;
  display: -webkit-flex;
  display: flex;
  clear: both;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

}

.signInSpacing p{
  margin: 0;

}

.signInLinks{
  width: 100%;
}
/* Sign Up*/
.signUpContainer{
  background-color: #F1F1F1;
  height: 100vh;
}
.signUpCard{
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  width: 500px;
}

.signUpSpacing{
  margin-top: 30px;
}

.signInField{
  border-radius: 25;
  background-color: white;
}

.forgotPw{
  color: white;
}
a{
  color: inherit;
  text-decoration: none;
}
a:hover{
  color: #2196f3;
}

input:-webkit-autofill {
-webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}

/* Profile */
.profile{
  min-height: 590px;
}
.profileMain{
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
}
.profileInfo{
  min-height: 550px;
}
.profileContent{
  min-height: 550px;
  overflow-y: auto;
}
.profilePicture{
  width: 100%;
}
.profileField{
  margin: 20 20 20 20;
}
.editProfileButton{
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.closeEdit{
  float: right;
}
.editButton{
  float: right;
}
.editSection{
  clear: both;
}
.dialog{
  width: 600px;
}

.wrapper{
  height: 100%;
}
li{
  list-style: none;
}

div.profileCreation {
  margin: 30px auto;
  padding-top: 0px;
  text-align: center;
  width: 95%;
}

header.PCTitle{
  font-size: 30px;
  text-transform: capitalize;
  font-style: normal;
  font-family: sans-serif;
}

div.popUp{
  width:1000px;
}

button{
  float:left;
  padding-bottom: 10px;
}
p {
  -webkit-align-content: center;
          align-content: center;
}

.school-card{
    height: 100%;
    width: 300px;
}

.school{
    min-height: 500px;
}
.searchContainer{
    width: auto;
}

.searchContent{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.searchSchoolBar{
    background-color: white;
    border-radius: 10px;
    width: auto;
  }

.school-container{
    margin-top:20px;
}

.school-info{
    margin-left: 20px;
    width: 300px;
    transition: all 0.3s linear;
    text-align: center;
}

@media (max-width: 959px){
    .school-info{
        margin-top: 0 !important;
    }
}

.school-info img {
    max-width: 100%;
}

.school-logo{
    height: 75px;
    width: 75px;
    display: block;
    margin: auto;
}
.name{
    font-size: 15px;
}
.location{
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.flag{
    margin-right: 12px;
}

.MuiPaper-elevation1-173 {
    box-shadow: none !important;
}

.MuiGrid-item-216 {
    padding:0 !important;
    margin:12px !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}


.program-title{
    font-size: 20px;
    width: 100%;
}

.program-price {
    font-weight: normal;
    float: right;
}

.program-description h4{
    display: none;
}

.mapbox-marker{
    
}


.program-card{
    height: 100%;
}

.program-card .program-logo{
    float: left;
    width: 75px;
    margin-right: 20px;
}
