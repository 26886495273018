.school-card{
    height: 100%;
    width: 300px;
}

.school{
    min-height: 500px;
}
.searchContainer{
    width: auto;
}

.searchContent{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchSchoolBar{
    background-color: white;
    border-radius: 10px;
    width: auto;
  }

.school-container{
    margin-top:20px;
}

.school-info{
    margin-left: 20px;
    width: 300px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    text-align: center;
}

@media (max-width: 959px){
    .school-info{
        margin-top: 0 !important;
    }
}

.school-info img {
    max-width: 100%;
}

.school-logo{
    height: 75px;
    width: 75px;
    display: block;
    margin: auto;
}
.name{
    font-size: 15px;
}
.location{
    font-size: 12px;
    display: flex;
    align-items: center;
}
.flag{
    margin-right: 12px;
}

.MuiPaper-elevation1-173 {
    box-shadow: none !important;
}

.MuiGrid-item-216 {
    padding:0 !important;
    margin:12px !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}


.program-title{
    font-size: 20px;
    width: 100%;
}

.program-price {
    font-weight: normal;
    float: right;
}

.program-description h4{
    display: none;
}

.mapbox-marker{
    
}


.program-card{
    height: 100%;
}

.program-card .program-logo{
    float: left;
    width: 75px;
    margin-right: 20px;
}